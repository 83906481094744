import React from 'react'
import { graphql, Link } from 'gatsby'
import ArticleCard from '../components/reusable/ArticleCard'
import Seo from '../components/global/Seo'

export default function LatestNewsArchive(props) {
  const data= props.data
  const {current, totalPages} = props.pageContext
  
  return (
    <div className="blog">
      <Seo seo={data.page.seo} />
      <section className='articles'>
        <div className="articles_inner">
          <div className="wrapper grid">
            {data.allWpPost.nodes.map(post => <ArticleCard post={post} />)}
          </div>
          <div className="wrapper">
            <div className="wp-pagenavi wrapper" role="navigation">
                {current !== 1 ?
                    <Link className="previouspostslink" rel="previous" to={current === 2 ? `/latest-news/`: `/latest-news/page/${current-1}/`} >Prev</Link>
                : ""
                }
                {Array.from({ length: totalPages }, (_, i) => (
                    i === current - 1 ?
                        <span aria-current="page" className="current">{i + 1}</span>
                    :
                    <Link
                    className={i === totalPages - 1 ? 'page larger last-child' : 'page larger'}
                    to={`${i === 0 ? '/latest-news' : '/latest-news/page/'+ (i + 1)}/`}
                    >
                    {i + 1}
                    </Link>
                ))}
                {current !== totalPages ?
                    <Link className="nextpostslink" rel="next" to={`/latest-news/page/${current+1}/`}>Next</Link>
                : ""
                }
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export const LatestNewsArchiveQuery = graphql`
  query LatestNewsArchiveQuery($offset: Int, $postsPerPage: Int) {
    page: wpPage(title: {eq: "Latest News"}){
      seo{
        metaDesc
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphTitle
        title
        opengraphImage {
          publicUrl
        }
        twitterImage {
          publicUrl
        }
      }
    }
    allWpPost(skip: $offset, limit: $postsPerPage, sort: {order: DESC, fields: date}) {
      nodes {
        title
        excerpt
        uri
        acfCustomThumbnail {
          customThumbnail {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`