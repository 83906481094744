import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import parse from "html-react-parser";

export default function ArticleCard({ post }) {
  // if(post?.acfCustomThumbnail?.customThumbnail?.localFile?.childImageSharp?.gatsbyImageData === null){
  //   console.log({title: post.title})
  // }
  return (
    <article className="articles__card" role="article">
      {post?.acfCustomThumbnail?.customThumbnail?.localFile?.childImageSharp ===
        null ||
      post?.acfCustomThumbnail?.customThumbnail?.localFile?.childImageSharp ===
        undefined ? (
        ""
      ) : (
        <GatsbyImage
          image={
            post.acfCustomThumbnail.customThumbnail.localFile.childImageSharp
              .gatsbyImageData
          }
          alt={post.acfCustomThumbnail.customThumbnail.altText}
        />
      )}
      <h2 className="articles__card__heading heading heading--medium">
        {post.title}
      </h2>
      <div className="articles__card__text text text--small">
        {parse(post.excerpt)}
      </div>
      <Link to={post.uri} className="btn btn--black">
        Read more
      </Link>
    </article>
  );
}
